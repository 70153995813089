import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be 2 characters minimum')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  company: Yup.string()
    .min(2, 'Company name must be 2 characters minimum')
    .required('Company is required'),
  zip: Yup.string()
    .min(2, 'Zip/Post code must be 2 characters minimum')
    .required('Zip/Post code is required'),
  country: Yup.string()
    .min(2, 'Country must be 2 characters minimum')
    .required('Country is required'),
  phone: Yup.string()
    .min(2, 'Phone must be 2 characters minimum')
    .required('Phone is required'),    
  manager: Yup.string()
    .min(2, 'IT Manager must be 2 characters minimum')
    .required('IT Manager is required'), 
  cpuid: Yup.string()
    .min(2, 'CPUID must be 2 characters minimum')
    .required('CPUID is required'),
  cpumodel: Yup.string()
    .min(2, 'CPU Model must be 2 characters minimum')
    .required('CPU Model is required'), 
  os: Yup.string()
    .required('OS is required'), 
})

const formUrl = 'https://script.google.com/macros/s/AKfycbwrs66yT-IV4og4OehH_IfvAI8lcvxeFhJebPIE1GfkMTdQDTEebnQ9H85DF0z6m5q2sg/exec'

const messageConfirm = () => {
  return toast.info("Thank you for contacting us.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class FormDownloads extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null, 
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true, 
        pageUrl: location.href 
      })
    })
  }

  handleChange = value => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol lg="9" md="12" className="md-0 mb-5">
            <Formik
              initialValues={{
                name: '',
                email: '',
                company: '',
                zip: '',
                country: '',             
                phone: '',
                manager: '',
                cpuid: '',  
                cpumodel: '',                                
                zwarezvse: '',
                zwarezos: '',
                zwarewebserver: '',
                automontcpip: '',
                os: '',
                message: '',
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  Name: values.name,
                  Email: values.email,
                  Company: values.company,
                  Zip: values.zip,
                  Country: values.country,                  
                  Phone: values.phone,
                  Manager: values.manager,
                  Cpuid: values.cpuid,
                  Cpumodel: values.cpumodel,                                     
                  zwarezvse: values.zwarezvse,
                  zwarezos: values.zwarezos,
                  zwarewebserver: values.zwarewebserver,
                  automontcpip: values.automontcpip,
                  OS: values.os,
                  Message: values.message,
                  Subscribe: values.subscribe,
                }
                try {
                  const response = axios({
                      method: 'get',
                      url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&name=${encodeURIComponent(values.name)}&email=${encodeURIComponent(values.email)}&company=${encodeURIComponent(values.company)}&zip=${encodeURIComponent(values.zip)}&country=${encodeURIComponent(values.country)}&phone=${encodeURIComponent(values.phone)}&manager=${encodeURIComponent(values.manager)}&cpuid=${encodeURIComponent(values.cpuid)}&cpumodel=${encodeURIComponent(values.cpumodel)}&zwarezvse=${encodeURIComponent(values.zwarezvse)}&zwarezos=${encodeURIComponent(values.zwarezos)}&zwarewebserver=${encodeURIComponent(values.zwarewebserver)}&automontcpip=${encodeURIComponent(values.automontcpip)}&os=${encodeURIComponent(values.os)}&message=${encodeURIComponent(values.message)}&subscribe=${encodeURIComponent(values.subscribe)}`
                    })
                      //console.log(response)
                      this.setState({values: '', expired: 'true'})
                      resetForm()
                      messageConfirm()
                    } catch (e) {
                      console.log(`Axios request failed: ${e}`)
                    }
                  setSubmitting(false)
                }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form name="contact" method="post" action="" data-netlify="true" data-netlify-honeypot="bot-field" >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />

                  <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">Download request form</h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>                  


                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="name"> Name <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="name "name="name" aria-label="name" value={values.name} placeholder="Your full name" className={`form-control ${ touched.name && errors.name ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="name" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="email"> Email <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="email" name="email" aria-label="email" value={values.email} placeholder="Your email address" className={`form-control ${ touched.email && errors.email ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="email" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <hr />

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="company"> Company Name <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="company" name="company" aria-label="company" value={values.company} placeholder="Your company name" className={`form-control ${ touched.company && errors.company ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="company" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="zip"> Zip/Postcode <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="zip" name="zip" aria-label="text" value={values.zip} placeholder="Your zip/postcode" className={`form-control ${ touched.zip && errors.zip ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="zip" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>                  

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="country"> Country <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="country" name="country" aria-label="country" value={values.country} placeholder="Your country of residence" className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="country" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="phone"> Phone <span style={{ color: 'red' }}>*</span></label>
                        <Field type="tel" id="phone" name="phone" aria-label="phone" value={values.phone} placeholder="Your phone number" className={`form-control ${ touched.phone && errors.phone ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="phone" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="manager"> IT Manager <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="manager" name="manager" aria-label="manager" value={values.manager} placeholder="IT Manager's name" className={`form-control ${ touched.manager && errors.manager ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="manager" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <hr />           

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="cpuid"> REAL CPUID <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="cpuid" name="cpuid" aria-label="cpuid" value={values.cpuid} placeholder="What is your REAL CPUID?" className={`form-control ${ touched.cpuid && errors.cpuid ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="cpuid" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="cpumodel"> CPU model number <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="cpumodel" name="cpumodel" aria-label="cpumodel" value={values.cpumodel} placeholder="e.g. 2096-A01" className={`form-control ${ touched.cpumodel && errors.cpumodel ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="cpumodel" className="invalid-feedback" />
                      </div>
                    </MDBCol>                    
                  </MDBRow>                       
                
                  
                  <div className="mb-4 grey-text font-w-600 text-medium">
                  <label> Product <span style={{ color: 'red' }}>*</span> </label>
                  </div>
                  <MDBRow>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="zwarezvse"
                        name="zwarezvse"
                        className={`form-check-input ${ touched.zwarezvse && errors.zwarezvse ? 'is-invalid' : '' }`}
                      />
                      <label htmlFor="zwarezvse">z/Ware for z/VSE or VSE/ESA</label>
                      <ErrorMessage component="div" name="zwarezvse" className="invalid-feedback" />
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="zwarezos"
                        name="zwarezos"
                        className={`form-check-input ${ touched.zwarezos && errors.zwarezos ? 'is-invalid' : '' }`}
                      />
                      <label htmlFor="zwarezos">z/Ware for z/OS</label>
                      <ErrorMessage component="div" name="zwarezos" className="invalid-feedback" />
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="zwarewebserver"
                        name="zwarewebserver"
                        className={`form-check-input ${ touched.zwarewebserver && errors.zwarewebserver ? 'is-invalid' : '' }`}
                      />
                      <label htmlFor="zwarewebserver">z/Web-Server for z/VM-VM/ESA</label>
                      <ErrorMessage component="div" name="zwarewebserver" className="invalid-feedback" />
                    </MDBCol>                   

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="automontcpip"
                        name="automontcpip"
                        className={`form-check-input ${ touched.automontcpip && errors.automontcpip ? 'is-invalid' : '' }`}
                      />
                      <label htmlFor="automontcpip">AUTOMON/TCPIP</label>
                      <ErrorMessage component="div" name="automontcpip" className="invalid-feedback" />
                    </MDBCol>                    
                  </MDBRow>               

                  <hr />

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="os"> OS <span style={{ color: 'red' }}>*</span> </label>
                        <Field
                          type="os"
                          name="os"
                          id="os"
                          as="select"
                          value={values.os}
                          placeholder="Please select an option"
                          className={`form-control ${ touched.os && errors.os ? 'is-invalid' : '' }`}
                        >
                          <option value="" label="Please select a value">
                            {' '}
                          </option>
                          <option value="None" label="None">
                            None
                          </option>
                          <option value="z/VSE" label="z/VSE">
                            z/VSE
                          </option>
                          <option value="z/OS" label="z/OS">
                            z/OS
                          </option>
                          <option value="z/VM" label="z/VM">
                            z/VM
                          </option>
                          <option value="Other" label="Other">
                            Other
                          </option>
                        </Field>
                        <ErrorMessage component="div" name="os" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>  

                  <hr />

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="message">Message</label>
                        <Field type="textarea" component="textarea" name="message" aria-label="message" value={values.message} placeholder="Need to leave us a message?" className={`form-control ${ touched.message && errors.message ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="message" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  </section>

                  <hr />                   

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        To receive occasional updates about illustro
                        products, services, news and events, please tick the box
                        below. You can unsubscribe at any time.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          aria-label="subscribe"
                          className={`form-check-input ${ touched.subscribe && errors.subscribe ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="subscribe">
                          Yes, I would like to receive communications from
                          illustro.
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please
                        visit our{' '}
                        <Link to="/privacy-statement/" className="effect">
                          Privacy Statement
                        </Link>
                        .
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>

     
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default FormDownloads